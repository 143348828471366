/* eslint-disable react/no-array-index-key */
import React, {
  useRef, useState, Fragment, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import useResizeObserver from 'use-resize-observer';

export const ImageCarousel = ({ images, video }) => {
  const sliderRef = useRef(null);
  const { ref, height } = useResizeObserver();
  const [currSlide, setCurrentSlide] = useState(1);
  let imageGalleryLength = images.length;

  useEffect(() => {
    if (video) {
      if (Object.keys(video).length >= 1) {
        imageGalleryLength = images.length + 1;
        images.unshift(video);
      }
    }
  }, []);

  const renderCustomSlickArrows = () => {
    const prevSlideCount = currSlide === 0 ? setCurrentSlide(imageGalleryLength) : currSlide - 1;
    const nextSlideCount = currSlide > imageGalleryLength ? setCurrentSlide(1) : currSlide + 1;

    return (
      <div className="carousel-pagination">
        <div className="slick-controls">
          <div className="slick-arrow-container">
            <button
              type="button"
              className="arrow slick-arrow-prev"
              aria-label="Previous"
              onClick={() => {
                sliderRef.current.slickPrev();
                setCurrentSlide(prevSlideCount);
              }}
            />
            <span>{currSlide}</span>
            <span>/</span>
            <span>{imageGalleryLength}</span>
            <button
              type="button"
              className="arrow slick-arrow-next"
              aria-label="Next"
              onClick={() => {
                sliderRef.current.slickNext();
                setCurrentSlide(nextSlideCount);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`carousel-wrapper ${images.length === 0 && 'empty'}`}>
      <Slider
        ref={sliderRef}
        arrows={false}
        fade
        speed={700}
        slidesToShow={1}
        slidesToScroll={1}
      >
        {images.map((img, index) => {
          if (img?.url) {
            return (
              <Fragment key={index}>
                <ReactPlayer
                  url={img.url}
                  width="100%"
                  height={height}
                  controls
                  playsinline
                  muted
                />
              </Fragment>
            );
          }
          if (img?.video) {
            return (
              <Fragment key={index}>
                <ReactPlayer
                  url={img.video.mp4Url}
                  width="100%"
                  height={560}
                  playing
                  muted
                  loop
                />
              </Fragment>
            );
          }
          if (img?.fluid) {
            return (
              <Fragment key={index}>
                <div ref={ref}>
                  <Img fluid={img.fluid} loading="eager" className="slide-image" alt={`Slide image ${img.index + 1}`} />
                  <small
                    className="caption"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: img.title,
                    }}
                  />
                </div>
              </Fragment>
            );
          }
          return null;
        })}
      </Slider>
      {imageGalleryLength > 1 && renderCustomSlickArrows() }
    </div>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  video: PropTypes.shape(),
};

ImageCarousel.defaultProps = {
  video: {},
};
