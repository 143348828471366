export const formatDate = (date) => {
  if (!date) return '';
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const jsDate = new Date(date);
  if (typeof jsDate.getMonth === 'function') {
    const formattedDate = [];
    let hour = jsDate.getHours();
    let timeOfDay = 'am';
    if (hour > 12) {
      hour -= 12;
      timeOfDay = 'pm';
    }
    formattedDate.date = jsDate.getDate();
    formattedDate.day = weekday[jsDate.getDay()];
    formattedDate.month = jsDate.toLocaleString('default', { month: 'short' });
    formattedDate.monthLong = jsDate.toLocaleString('default', { month: 'long' });
    formattedDate.year = jsDate.getFullYear();
    formattedDate.hour = `${hour}${timeOfDay}`;
    return formattedDate;
  }

  return null;
};
