import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { ImageCarousel } from '../components/imageCarousel';
import { formatDate } from '../components/formatDate';
import { DividerBlock } from '../components/dividerBlock';
import ImageFadeIn from "react-image-fade-in"

const Artist = ({ data }) => {
  const [featured, setFeatured] = useState([]);
  const [mentioned, setMentioned] = useState([]);
  const artist = data.datoCmsArtist;
  const showGallery = Boolean(artist.gallery.length);
  const backLink = `/artist/${artist.slug}`;
  const backLinkText = 'artist';
  const allExhibitions = { ...data.allDatoCmsSingleExhibition.edges };
  const allArticles = { ...data.allDatoCmsSingleArticle.edges };

  const getArtistExhibitions = () => Object.values(allExhibitions)
    .filter((exhibition) => exhibition.node.artists
      .some((dataArtist) => dataArtist.artist && dataArtist.artist.id === artist.id));

  const getArtistArticles = () => Object.values(allArticles)
    .filter((exhibition) => exhibition.node.studioArtists
      .some((dataArtist) => dataArtist.id === artist.id));

  useEffect(() => {
    const artistExhibitions = getArtistExhibitions();
    setFeatured(artistExhibitions);
    const artistArticles = getArtistArticles();
    setMentioned(artistArticles);
  }, []);

  const fetchYear = (date) => {
    const dateObj = formatDate(date);
    return dateObj.year;
  };

  return (
    <Layout bgcolor="#fff" wrapperClass="artist-single" accentColor={artist.accentColor}>
      <HelmetDatoCms seo={artist.seoMetaTags} />
      <div className="artist-block">
        <div className="main-title col">
          <h1 className="gothic--large title large-title">{artist.name}</h1>
        </div>
        <div className="sidebar col">
          <Link to="/studios" className="sidebar-text">Studio Artists</Link>
        </div>
        <div className="artist-intro row">
          {artist.profileImage && (
          <div className="image-wrap col">
            <ImageFadeIn
            src={artist.profileImage.url}
            alt={artist.profileImage.alt}
						className="artist-profile-image"
          	/>
						<small
              className="caption artist-image-caption"
              dangerouslySetInnerHTML={{
                __html: artist.profileImage.title,
              }}
            />
          </div>
          )}
          <div className="intro-divider col">
            <DividerBlock accentColor={artist.accentColor} />
          </div>
        </div>
        <div className="artist-text row">
          <div className="text-wrap col">
            <span className="sans--regular">{artist.title}</span>
            <h4 className="sans--regular">
              {fetchYear(artist.startDate)}
            &nbsp;-&nbsp;
              {fetchYear(artist.endDate)}
            </h4>
            <div
              className="content artist-bio"
              dangerouslySetInnerHTML={{
                __html: artist.description,
              }}
            />
          </div>
        </div>
        {showGallery && (
        <div className="artist-gallery col">
          <ImageCarousel images={artist.gallery} video={artist.externalVideo} />
        </div>
        )}
      </div>
      {featured.length > 0 && (
        <div className="artist-featured-in-row container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">Featured in</span>
          </div>
          <div className="tiles tiles-4 col-sm-12 col-xs-6 col row spacing-0">
            {featured.map(({ node: feature }) => {
              const prettyStareDate = formatDate(feature.startDate);
              const prettyEndDate = formatDate(feature.endDate);
              return (
                <div className="tile col" key={feature.id}>
                  <Link to={`/exhibition/${feature.slug}`}>
                    {feature.featuredImage && (
											<ImageFadeIn
												src={feature.featuredImage.url}
												alt={feature.featuredImage.alt}
												className="tile-image"
											/>
                    )}
                    <div className="tile-text-wrap">
                      <h3 className="sans--regular exhibition-title" dangerouslySetInnerHTML={{ __html: feature.formattedTitle }} />
                      {feature.locations && (
                        <p className="exhibition-location">{feature.locations[0]?.location?.title}</p>
                      )}
                      <p className="exhibition-dates">
                        {`${prettyStareDate.date} ${prettyStareDate.month}`}
                        &nbsp;-&nbsp;
                        {`${prettyEndDate.date} ${prettyEndDate.month} ${prettyEndDate.year}`}
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {mentioned.length > 0 && (
        <div className="artist-mentioned-in-row container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">Mentioned in</span>
          </div>
          <div className="tiles tiles-4 col-sm-12 col-xs-6 col row spacing-0">
            {mentioned.map(({ node: article }) => (
              <div className="tile article-tile-wrapper col" key={article.id}>
                <Link
                  to={`/article/${article.slug}`}
                  state={{ backLink, backLinkText }}
                  className="article-tile"
                >
                  {article.featuredImage && (
										<ImageFadeIn
												src={article.featuredImage.url}
												alt={article.featuredImage.alt}
												className="tile-image"
											/>                  
									)}
                  <div className="tile-text-wrap">
                    <span className="tile-type">
                      {article.articleType.title}
                    </span>
                    <h3
                      className="tile-title"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: article.formattedTitle,
                      }}
                    />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="artist-bottom-divider col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col">
        <DividerBlock accentColor={artist.accentColor} />
      </div>
    </Layout>
  );
};

Artist.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Artist;

export const query = graphql`
  query ArtistQuery($slug: String!) {
    datoCmsArtist(slug: { eq: $slug }) {
      id
      accentColor {
        hex
      }
      slug
      name
      title
      startDate
      endDate
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      profileImage {
        title
				url(imgixParams: { w: "600" h: "600", fit: "crop", crop: "focalpoint" })
				alt
      }
      gallery {
        title
        fluid(maxHeight: 750) {
          ...GatsbyDatoCmsSizes_noBase64
        }
        video {
          mp4Url
        }
      }
      externalVideo {
        url
        providerUid
        title
      }
    }
    allDatoCmsSingleExhibition(
      sort: {
        fields: [meta___publishedAt],
        order: DESC
      },
      filter: {meta: {isValid: {eq: true}, status: {ne: "draft"}}}
    ) {
      edges {
        node {
          id
          formattedTitle
          slug
          featuredImage {
						url(imgixParams: { w: "600", ar: "5:3", fit: "crop", crop: "focalpoint" })
						alt
          }
          locations {
            location {
              title
            }
          }
          startDate
          endDate
          artists {
            ... on DatoCmsStudioArtist {
              artist {
                id
              }
            }
          }
        }
      }
    }
    allDatoCmsSingleArticle(
          sort: {
            fields: [meta___publishedAt],
            order: DESC
          },
          filter: {meta: {isValid: {eq: true}, status: {ne: "draft"}}}
        ) {
      edges {
        node {
          id
          featuredImage {
            url(imgixParams: { w: "600" h: "339", fit: "crop", crop: "focalpoint" })
						alt
          }
          formattedTitle
          slug
          articleType {
            title
          }
          studioArtists {
            id
          }
        }
      }
    }
  }
`;
